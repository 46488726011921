import { createStore as reduxCreateStore } from "redux"
import { reducer } from "./datastore"

export const createStore = preloadedState => {
  if (typeof window !== 'undefined') {
    return reduxCreateStore(
      reducer,
      preloadedState,
      typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  } else {
    return reduxCreateStore(
      reducer,
      preloadedState,
    );
  }
}

export default createStore
