const initialState = {
  fanName: "",
  partnerName: "",
  favoriteTeamName: "",
  gamePairs: [["", ""]],
  tradeOffs: [""],
}

// Action types

export const ADD_GAME_PAIR_ACTION = "ADD_GAME_PAIR"
export const REMOVE_GAME_PAIR_ACTION = "REMOVE_GAME_PAIR"
export const SET_GAME_PAIR_ACTION = "SET_GAME_PAIR"
export const ADD_TRADE_OFF_ACTION = "ADD_TRADE_OFF"
export const REMOVE_TRADE_OFF_ACTION = "REMOVE_TRADE_OFF"
export const SET_TRADE_OFF_ACTION = "SET_TRADE_OFF"
export const SET_FAVORITE_TEAM_NAME_ACTION = "SET_FAVORITE_TEAM_NAME"
export const SET_PARTNER_NAME_ACTION = "SET_PARTNER_NAME"
export const SET_FAN_NAME_ACTION = "SET_FAN_NAME"

// Reducer

export const reduceFormData = (state = initialState, action) => {
  const { type, payload } = action
  // eslint-disable-next-line default-case
  switch (type) {
    case ADD_GAME_PAIR_ACTION: {
      return {
        ...state,
        gamePairs: [...state.gamePairs, payload || ["", ""]],
      }
    }
    case REMOVE_GAME_PAIR_ACTION: {
      return {
        ...state,
        gamePairs: state.gamePairs.filter((_, i) => payload !== i),
      }
    }
    case SET_GAME_PAIR_ACTION: {
        const { pair: newPair, index } = payload;
      return {
        ...state,
        gamePairs: state.gamePairs.map((pair, i) => (i === index ? newPair : pair)),
      }
    }
    case ADD_TRADE_OFF_ACTION: {
      return {
        ...state,
        tradeOffs: [...state.tradeOffs, payload || [""]],
      }
    }
    case REMOVE_TRADE_OFF_ACTION: {
      return {
        ...state,
        tradeOffs: state.tradeOffs.filter((_, i) => payload !== i),
      }
    }
    case SET_TRADE_OFF_ACTION: {
      const { index, tradeOff: newTradeOff } = payload
      return {
        ...state,
        tradeOffs: state.tradeOffs.map((oldTradeOff, i) =>
          index === i ? newTradeOff : oldTradeOff
        ),
      }
    }
    case SET_FAVORITE_TEAM_NAME_ACTION: {
      return {
        ...state,
        favoriteTeamName: payload,
      }
    }
    case SET_FAN_NAME_ACTION: {
      return {
        ...state,
        fanName: payload,
      }
    }
    case SET_PARTNER_NAME_ACTION: {
      return {
        ...state,
        partnerName: payload,
      }
    }
    default:
      return state;
  }
}

// Action Creators.

export const addGamePair = optionalPair => ({
  type: ADD_GAME_PAIR_ACTION,
  payload: optionalPair,
})

export const removeGamePair = index => ({
  type: REMOVE_GAME_PAIR_ACTION,
  payload: index,
})

export const setGamePair = (index, pair) => ({
  type: SET_GAME_PAIR_ACTION,
  payload: { index, pair },
})

export const addTradeOff = optionalTradeOff => ({
  type: ADD_TRADE_OFF_ACTION,
  payload: optionalTradeOff,
})

export const removeTradeOff = index => ({
  type: REMOVE_TRADE_OFF_ACTION,
  payload: index,
})

export const setTradeOff = (index, tradeOff) => ({
  type: SET_TRADE_OFF_ACTION,
  payload: { index, tradeOff },
})

export const setFavoriteTeamName = favoriteTeamName => ({
  type: SET_FAVORITE_TEAM_NAME_ACTION,
  payload: favoriteTeamName,
})

export const setPartnerName = partnerName => ({
  type: SET_PARTNER_NAME_ACTION,
  payload: partnerName,
})

export const setFanName = fanName => ({
  type: SET_FAN_NAME_ACTION,
  payload: fanName,
})

// Selectors

export const getGamePairs = ({ formData }) => formData.gamePairs
export const getTradeOffs = ({ formData }) => formData.tradeOffs
export const getFavoriteTeamName = ({ formData }) => formData.favoriteTeamName
export const getPartnerName = ({ formData }) => formData.partnerName
export const getFanName = ({ formData }) => formData.fanName
