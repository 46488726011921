// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-orig-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index-orig.js" /* webpackChunkName: "component---src-pages-index-orig-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-page-2-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-personalized-prenup-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/personalized-prenup.js" /* webpackChunkName: "component---src-pages-personalized-prenup-js" */),
  "component---src-pages-prenup-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/prenup.js" /* webpackChunkName: "component---src-pages-prenup-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

