module.exports = [{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/datastore/create-store","serialize":{"space":0,"isJSON":true,"unsafe":false}},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Oswald:300,600,700"]},"custom":{"families":["HCo Champion Lightwt","HCo Champion Welterwt","HCo Champion Middlewt"]}},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N6MGM4F","includeInDevelopment":false},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
